var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-5"},[_c('overlay',{attrs:{"loading":_vm.loading}}),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{staticClass:"text-h6 black--text pb-0",attrs:{"cols":"12"}},[_vm._v(" Profissionais ")]),_c('v-col',{staticClass:"subtitle-2 grey--text pt-0",attrs:{"cols":"12"}},[_vm._v(" Profissionais cadastrados no sistema ")])],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.$router.push({
          name: 'AdminCreateProfessionals',
        })}}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Novo cadastro ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0 rounded-0",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"mb-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"10"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Buscar por nome","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.professionals,"search":_vm.search,"loading":!_vm.professionals,"item-key":"id","custom-filter":_vm.filterOnlyText},scopedSlots:_vm._u([{key:"item.menu",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(item.role_id === 'Ativo')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.disableProfissional(item.id)}}},[_c('v-list-item-title',{staticStyle:{"color":"red"}},[_vm._v(" Desativar profissional ")])],1):_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.turnProfessionalActive(item.id)}}},[_c('v-list-item-title',{staticStyle:{"color":"green"}},[_vm._v(" Ativar profissional ")])],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }