<template>
  <v-row class="ma-5">
    <overlay :loading="loading" />
    <v-col cols="12" sm="6">
      <v-row>
        <v-col cols="12" class="text-h6 black--text pb-0">
          Profissionais
        </v-col>
        <v-col cols="12" class="subtitle-2 grey--text pt-0">
          Profissionais cadastrados no sistema
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex justify-end">
      <v-btn
        @click="
          $router.push({
            name: 'AdminCreateProfessionals',
          })
        "
        class="primary"
      >
        <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
        Novo cadastro
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card outlined class="elevation-0 rounded-0">
        <v-card-title class="mb-2">
          <v-row>
            <v-col cols="12" sm="8" md="10">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Buscar por nome"
                dense
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2"> </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="professionals"
          :search="search"
          :loading="!professionals"
          item-key="id"
          :custom-filter="filterOnlyText"
        >
          <template v-slot:[`item.menu`]="{ item }">
            <div>
              <v-menu offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn v-bind="attrs" v-on="on" text>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="item.role_id === 'Ativo'"
                    link
                    @click="disableProfissional(item.id)"
                  >
                    <v-list-item-title style="color: red">
                      Desativar profissional
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-else
                    link
                    @click="turnProfessionalActive(item.id)"
                  >
                    <v-list-item-title style="color: green">
                      Ativar profissional
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
// import Excel from "../../../components/Excel/Excel.vue";
import Overlay from "../../../components/Overlay/Overlay.vue";

export default {
  components: {
    // Excel,
    Overlay,
  },
  data: () => ({
    search: null,
    reason: null,
    loading: false,
    valid: true,
    professionals: [],
    openDeleteProfessional: false,
    json_fields_patient: {
      Nome: {
        field: "name",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      CPF: "cpf",
      "E-mail": {
        field: "email",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Criação: {
        field: "created_at",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
    },
    deleteProfissional: null,
    turn_professional_active: { role_id: 10 },
    turn_professional_disabled: { role_id: 16 },
  }),
  mounted() {
    this.showProfessionals();
  },
  methods: {
    async turnProfessionalActive(id) {
      try {
        await this.$axios.put("users/" + id, this.turn_professional_active);

        this.showProfessionals();

        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Status do profissional alterado com sucesso",
        });
      } catch (error) {
        this.$swal({
          toast: false,
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar alterar o status deste profissional",
        });
      }
    },
    async disableProfissional(id) {
      try {
        await this.$axios.put("users/" + id, this.turn_professional_disabled);

        this.showProfessionals();

        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Status do profissional alterado com sucesso",
        });
      } catch (error) {
        this.$swal({
          toast: false,
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar alterar o status deste profissional",
        });
      }
    },
    async showProfessionals() {
      try {
        this.loading = true;
        const response = await this.$axios("users", {
          params: {
            adminList: true,
          },
        });

        this.professionals = response.data.map((el) => {
          return {
            id: el.id,
            name: el.name,
            created_at: this.formatLastUpdate(el.created_at),
            role_id: el.role_id == 10 ? "Ativo" : "Inativo",
            email: el.email,
            cpf: el.cpf,
          };
        });

        this.loading = false;
      } catch (error) {
        const msg = error.response.error;

        this.loading = false;
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar os profissionais." + msg,
        });
      }
    },
    filterOnlyText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toLowerCase().toString().indexOf(search.toLowerCase()) !== -1
      );
    },
    formatLastUpdate(date) {
      if (!date) return "";

      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    headers() {
      return [
        { text: "Nome", value: "name", width: 250, align: "center" },
        { text: "CPF", value: "cpf", width: 200, align: "center" },
        { text: "E-mail", value: "email", width: 250, align: "center" },
        { text: "Status", value: "role_id", width: 250, align: "center" },
        {
          text: "Data de criação",
          value: "created_at",
          width: 220,
          align: "center",
        },
        { text: "Ação", value: "menu", width: 150, align: "center" },
      ];
    },
  },
};
</script>
